import req from '@/assets/scripts/request'
import { Export } from '@/types/export'

export interface GetSalesParams {
  pageSize?: number;
  page?: number;
  status: number | null;
  dateStart: string;
  dateEnd: string;
  markers: number[];
  sort?: string
  searchStr: string
}

interface GetSalesItemImage {
  j40: string;
  j60: string;
  w40: string;
  w60: string;
}

export interface GetSalesItem {
  id: number;
  status: number;
  name: string;
  ozon_id: null | number;
  ozon_product_name: null | string;
  ozon_product_image: null | string;
  artikul_postavshika: null | string;
  predmet: null | string;
  artikul_wb: null | string;
  offer_id: null | string;
  marker: null | number;
  order_count_total: null | string;
  order_sum_total: null | string;
  sale_count_total: null | string;
  sale_sum_total: null | string;
  wb_id: null | number;
  wb_product_image: null | string;
  images: {
    ozon: GetSalesItemImage;
    wb: GetSalesItemImage;
  }
}

export interface GetSalesScheduleItem {
  date: string;
  order_count: number;
  order_sum: number;
  sale_count: number;
  sale_sum: number;
}

export interface GetSalesResponse {
  items: GetSalesItem[];
  productCount: number;
  pageSize: number;
  pageCount: number;
  page: number;
  totalSales: {
    order_count_total: number | string;
    order_sum_total: number | string;
    sale_count_total: number | string;
    sale_sum_total: number | string;
  },
  schedule: GetSalesScheduleItem[];
}

export async function getSales (params: GetSalesParams): Promise<GetSalesResponse> {
  return await req.post('sales', params)
}

export interface GetMPSalesParams {
  page: number;
  pageSize: number;
  searchStr: string;
  start: string;
  end: string;
  status: number | null;
  sort: string;
  markerId: number | null;
}

export async function getWbSales (params: GetMPSalesParams): Promise<any> {
  return await req.post('sales/get-wb', params)
}

export async function getOzonSales (params: GetMPSalesParams): Promise<any> {
  return await req.post('sales/get-ozon', params)
}

export interface GetChartItem {
  date: string;
  order_count: number | string;
  order_sum: number | string;
  sale_count: number | string;
  sale_sum: number | string;
}
export type GetChartResponse = GetChartItem[]

export interface GetChartParams {
  start: string;
  end: string;
  searchStr: string;
  status: number | null;
  markerId: number | null;
}

export async function getWbChart (params: GetChartParams): Promise<GetChartResponse> {
  return await req.post('sales/get-wb-chart', params)
}

export async function getOzonChart (params: GetChartParams): Promise<GetChartResponse> {
  return await req.post('sales/get-ozon-chart', params)
}

export interface ExportGeneralParams {
  status: number | null;
  dateStart: string;
  dateEnd: string;
  markers: number[];
  sort: string;
  searchStr: string;
}

export interface ExportGeneralResponse {
  result: {
    status: 'waiting' | 'error',
    export_id: number
  },
  'export_id': number
}

export async function exportGeneral (params: ExportGeneralParams): Promise<ExportGeneralResponse> {
  return await req.post('sales/export-general', params)
}

export interface ExportMPParams {
  searchStr: string;
  start: string;
  end: string;
  status: number | null;
  sort: string;
  markerId: number | null;
}

export async function exportWbProducts (params: ExportMPParams): Promise<Export> {
  return await req.post('sales/export-wb', params)
}

export async function exportOzonProducts (params: ExportMPParams): Promise<Export> {
  return await req.post('sales/export-ozon', params)
}
