import cookie from '@/utils/cookie'
const accountsKey = 'accounts'

export default {
  get () {
    return cookie.getCookie(accountsKey)
  },
  set (accounts: string) {
    cookie.setCookie(accountsKey, accounts, { secure: true, 'max-age': 28800 })
  },
  unset () {
    cookie.deleteCookie(accountsKey)
  },
  addAccount (data: { userId: string }, accounts: Array<object>) {
    if (data !== null) {
      // eslint-disable-next-line
      // @ts-ignore
      if (!accounts.find((x) => x.userId === data.userId && x.organization === data.organization)) {
        accounts.push(data)
      }
    }
    return accounts
  }
}
