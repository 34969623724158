import accounts from '@/utils/accounts'
import router from '@/router'

const state = {
  accountPick: Object,
  accountsList: accounts.get(),
  organization: ''
}

const getters = {
  accountPick (state: any) {
    return state.accountPick
  },
  accountsList (state: any) {
    return state.accountsList
  },
  organization (state: any) {
    return state.organization
  }
}

const actions = {
  setAccount ({ state, commit }: any, arrayNum: number) {
    commit('accountPick', arrayNum)
    commit('setOrganization', state.accountPick.organization)
  }
}

const mutations = {
  setAccounts (state: any, data: { userId: string }) {
    const accountsList = accounts.get()

    let accountsObj = []
    if (accountsList !== undefined) {
      accountsObj = JSON.parse(accountsList)
    }

    accountsObj = accounts.addAccount(data, accountsObj)
    state.accountPick = accountsObj[accountsObj.length - 1]

    // eslint-disable-next-line
    // @ts-ignore
    accounts.set(JSON.stringify(accountsObj))
    state.accountsList = accounts.get()
  },
  accountPick (state: any, arrayNum: number) {
    const accountsList = state.accountsList
    if (accountsList !== undefined) {
      const accountArray = JSON.parse(accountsList)
      if (accountArray[arrayNum] !== undefined) {
        state.accountPick = JSON.parse(accountsList)[arrayNum]
      }
    }
  },
  setOrganization (state: any, organization: string) {
    state.organization = organization
  },
  logoutAcc (state: any, accNum: number) {
    const accountsList = accounts.get()
    let accountsObj = []
    if (accountsList !== undefined) {
      accountsObj = JSON.parse(accountsList)
      accountsObj.splice(accNum, 1)
      // eslint-disable-next-line
      // @ts-ignore
      accounts.set(JSON.stringify(accountsObj))
      state.accountsList = accounts.get()
      if (accountsObj.length === 0) {
        state.accountPick = {}
        accounts.unset()
        state.accountsList = accounts.get()
        router.push({ name: 'Login' })
      } else {
        state.accountPick = accountsObj[0]
      }
    }
  },
  logout (state: any) {
    state.accountPick = {}
    accounts.unset()
    state.accountsList = accounts.get()
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
