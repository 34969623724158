import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "data-table" }
const _hoisted_2 = { class: "data-table__wrapper" }
const _hoisted_3 = { class: "data-table__inner" }
const _hoisted_4 = {
  key: 0,
  class: "data-table__table-box"
}
const _hoisted_5 = { class: "data-table__table" }
const _hoisted_6 = { class: "data-table__body" }
const _hoisted_7 = {
  key: 1,
  class: "data-table__pagination"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_head = _resolveComponent("table-head")!
  const _component_text_cell = _resolveComponent("text-cell")!
  const _component_product_cell = _resolveComponent("product-cell")!
  const _component_status_cell = _resolveComponent("status-cell")!
  const _component_page_pagination = _resolveComponent("page-pagination")!
  const _component_not_found = _resolveComponent("not-found")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.dataTable?.productCount)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.dataTable?.items?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("table", _hoisted_5, [
                      _createVNode(_component_table_head, {
                        headers: _ctx.tableHeaders,
                        sort: _ctx.currentSort,
                        onSort: _ctx.changeSort
                      }, null, 8, ["headers", "sort", "onSort"]),
                      _createElementVNode("tbody", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataTable.items, (item, i) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            class: "data-table__row",
                            key: i
                          }, [
                            _createVNode(_component_text_cell, {
                              value: item.id
                            }, null, 8, ["value"]),
                            _createVNode(_component_product_cell, {
                              image: item?.images?.wb || item?.images?.ozon ,
                              "articul-ozon": item.offer_id,
                              "articul-wb": item.artikul_postavshika,
                              id: item.id,
                              name: item.name,
                              route: {name: 'ProductMain', params: { id: item.id }}
                            }, null, 8, ["image", "articul-ozon", "articul-wb", "id", "name", "route"]),
                            _createVNode(_component_status_cell, {
                              status: _ctx.getStatus(item.status)
                            }, null, 8, ["status"]),
                            _createVNode(_component_text_cell, {
                              value: +item.order_count_total,
                              type: 'number'
                            }, null, 8, ["value"]),
                            _createVNode(_component_text_cell, {
                              value: +item.order_sum_total,
                              type: 'number'
                            }, null, 8, ["value"]),
                            _createVNode(_component_text_cell, {
                              value: +item.sale_count_total,
                              type: 'number'
                            }, null, 8, ["value"]),
                            _createVNode(_component_text_cell, {
                              value: +item.sale_sum_total,
                              type: 'number'
                            }, null, 8, ["value"]),
                            _createVNode(_component_text_cell, {
                              list: item?.marker ? [{name: item.marker}] : [],
                              color: 'light'
                            }, null, 8, ["list"])
                          ]))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.dataTable?.pageCount > 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_page_pagination, {
                      "current-page": _ctx.dataTable?.page,
                      pages: _ctx.dataTable?.pageCount,
                      "page-size": _ctx.dataTable?.pageSize,
                      onChangePage: _ctx.changePage,
                      onChangePageSize: _ctx.changePageSize
                    }, null, 8, ["current-page", "pages", "page-size", "onChangePage", "onChangePageSize"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_not_found, { key: 1 }))
      ])
    ])
  ]))
}