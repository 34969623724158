import { Buffer } from 'buffer'
import store from '@/store'

const cors = process.env.NODE_ENV === 'production' ? 'same-origin' : 'cors'
const credentials =
  process.env.NODE_ENV === 'production' ? 'same-origin' : 'same-origin'
const basicAuthorization = `basic ${Buffer.from(
  'dev_mpdesk_ru:pI2xC8wY6qrS8d'
).toString('base64')}`

function getHeaders () {
  const token = store.getters.accountPick.userToken ?? ''
  const organization = store.getters.organization ?? ''
  if (!process.env.VUE_APP_LOCAL) {
    return {
      'Content-Type': 'application/json',
      organization: organization,
      ...(process.env.NODE_ENV !== 'production' && {
        Authorization: basicAuthorization
      }),
      ...{ Auth: 'Bearer ' + token }
    }
  }
  return {
    'Content-Type': 'application/json',
    ...(process.env.NODE_ENV !== 'production' && {
      Authorization: basicAuthorization
    }),
    ...{ Auth: 'Bearer ' + token }
  }
}

function getHeadersForUploadFiles () {
  const token = store.getters.accountPick.userToken ?? ''
  const organization = store.getters.organization ?? ''
  if (!process.env.VUE_APP_LOCAL) {
    return {
      organization: organization,
      ...(process.env.NODE_ENV !== 'production' && {
        Authorization: basicAuthorization
      }),
      ...{ Auth: 'Bearer ' + token }
    }
  }
  return {
    ...(process.env.NODE_ENV !== 'production' && {
      Authorization: basicAuthorization
    }),
    ...{ Auth: 'Bearer ' + token }
  }
}

export const urlBase = process.env.VUE_APP_API

export default {
  async get (urlPath: string, params: Record<string, any> = {}) {
    const isRelative = isRelativePath(urlBase)
    const url = isRelative ? new URL(window.location.origin + urlBase + urlPath) : new URL(urlBase + urlPath)
    if (params) url.search = new URLSearchParams(params).toString()
    const res = await fetch(url.toString(), {
      method: 'GET',
      mode: cors,
      credentials,
      headers: getHeaders()
    })
    if (res.ok) return await res.json()
    return null
  },
  async post (urlPath: string, data?: any) {
    const res = await fetch(`${urlBase}${urlPath}`, {
      method: 'POST',
      mode: cors,
      credentials,
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
    if (res.ok) return await res.json()
    return null
  },
  async delete (urlPath: string) {
    const res = await fetch(`${urlBase}${urlPath}`, {
      method: 'DELETE',
      mode: cors,
      credentials,
      headers: getHeaders()
    })
    if (res.ok) return await res.json()
    return null
  },
  async put (urlPath: string, data?: any) {
    const res = await fetch(`${urlBase}${urlPath}`, {
      method: 'PUT',
      mode: cors,
      credentials,
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
    if (res.ok) return await res.json()
    return null
  },
  async upload (urlPath: string, formData: any) {
    const res = await fetch(`${urlBase}${urlPath}`, {
      method: 'POST',
      body: formData,
      headers: getHeadersForUploadFiles()
    })
    if (res.ok) return await res.json()
    return null
  }
}

function isRelativePath (path: string): boolean {
  return path.startsWith('/') || path.startsWith('.')
}
