class Storage {
  #hash = '5NREYH'

  getSS (name: string) {
    const item = sessionStorage.getItem(`${this.#hash}_${name}`)
    if (item !== null) {
      return JSON.parse(item)
    }
    return null
  }

  setSS (name: string, data: any) {
    sessionStorage.setItem(`${this.#hash}_${name}`, JSON.stringify(data))
  }

  refresh () {
    const event = new Event('storageRefresh')
    window.dispatchEvent(event)
  }

  get user () {
    return this.getSS('user')
  }

  set user (data) {
    this.setSS('user', data)
    this.refresh()
  }

  get lastUpdate () {
    return this.getSS('last-update')
  }

  set lastUpdate (data) {
    this.setSS('last-update', data)
    this.refresh()
  }
}

export default new Storage()
