import req from '@/assets/scripts/request'

export interface GetJobLogsParams {
  pageSize?: number,
  page?: number,
  status?: number | null,
  'integration_type'?: number | null,
  comment?: string,
  dateFrom?: string,
  dateTo?: string,
  sort?: string
}

export interface JobLogsItem {
  id: number,
  job: string,
  comment: string,
  status: number,
  alias: string,
  'integration_type': null | number,
  'created_at': number,
  'updated_at': number
}

export interface JobLogsResponse {
  items: JobLogsItem[];
  productCount: number,
  pageSize: number,
  pageCount: number,
  page: number
}

export async function getJobLogs (params: GetJobLogsParams): Promise<JobLogsResponse> {
  return await req.post('job-log', params)
}

export async function deleteJobLog (id: number) {
  return await req.delete(`job-log/${id}`)
}

export interface JobLogIntegrations {
  [key: string]: string;
}

export async function getJobLogIntegrations (): Promise<JobLogIntegrations> {
  return await req.get('job-log/get-integrations')
}
