import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "data-table__thead" }
const _hoisted_2 = { class: "data-table__trow" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "data-table__head-name text-small",
  "data-testid": "data-table__head-name"
}
const _hoisted_5 = {
  class: "data-table__head-total text-small",
  "data-testid": "data-table__head-total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createElementVNode("tr", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, i) => {
        return (_openBlock(), _createElementBlock("th", {
          key: i,
          class: _normalizeClass(["data-table__head", header.classMod ? `data-table__head_${header.classMod}` : false]),
          "data-testid": "data-table__head"
        }, [
          (header.sort !== undefined)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["data-table__head-name data-table__head-name_sortable text-small", {'data-table__head-name_desc': header.sort.desc === _ctx.currentSort.value && _ctx.currentSort.type === 'desc', 'data-table__head-name_asc': header.sort.asc === _ctx.currentSort.value && _ctx.currentSort.type === 'asc'}]),
                onClick: ($event: any) => (_ctx.changeSort(header)),
                "data-testid": "data-table__head-name"
              }, _toDisplayString(header.name), 11, _hoisted_3))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(header.name), 1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(header.total) + " " + _toDisplayString(header.units), 1)
        ], 2))
      }), 128))
    ])
  ]))
}