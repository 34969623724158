import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "status-cell__text text"
}
const _hoisted_2 = {
  key: 1,
  class: "status-cell__text text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("td", {
    class: _normalizeClass(["status-cell", _ctx.classObject])
  }, [
    (_ctx.status?.name)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.status.name), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
  ], 2))
}