import req from '@/assets/scripts/request'

export interface GetUnattachedBarcodesParams {
  pageSize: number,
  page: number
}

export interface GetUnattachedBarcodesResponse {
  items: [{
    id: number,
    barcode: string,
    article: string,
    number: number | string
  }],
  productCount: number,
  pageSize: number,
  pageCount: number,
  page: number
}

export async function getUnattachedBarcodes (params?: GetUnattachedBarcodesParams): Promise<GetUnattachedBarcodesResponse> {
  return await req.post('wb/product/unattached-barcodes', params)
}

export interface RelatedBarcodeParams {
  wb_product_id: number,
  barcode: string
}

export interface RelatedBarcodeResponse {
  status: string,
  message: string
}

export async function relatedBarcode (params: RelatedBarcodeParams): Promise<RelatedBarcodeResponse> {
  return await req.post('wb/product/related-product-barcode', { ...params })
}
