import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["type", "disabled"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.route)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(["app-button", _ctx.classObject]),
        to: _ctx.route,
        "data-testid": "app-button"
      }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "app-button__icon",
                src: `/images/${_ctx.icon}.svg`,
                "data-testid": "app-button__icon"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "class"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(["app-button", _ctx.classObject]),
        type: _ctx.type,
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.eventClick && _ctx.eventClick(...args))),
        "data-testid": "app-button"
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "app-button__icon",
              src: `/images/${_ctx.icon}.svg`
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 10, _hoisted_2))
}