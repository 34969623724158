import req from '@/assets/scripts/request'
import { ProductImage } from '@/types/products'

export interface GetUnattachedProductsParams {
  status: number | null,
  sort: string,
  pageSize: number,
  page: number
}

export interface GetUnattachedProductsResponse {
  items: [{
    id: string,
    name: string,
    article: string,
    status: number,
    type: string,
    src: string,
    image: ProductImage | null,
  }],
  productCount: number,
  pageSize: number,
  pageCount: number,
  page: number
}

export async function getUnattachedProducts (params: GetUnattachedProductsParams): Promise<GetUnattachedProductsResponse> {
  return await req.post('unattached-products', { ...params })
}

export interface ExportUnattachedProductsParams {
  status: number | null,
  pageSize: number,
  page: number
}

export interface ExportUnattachedProductsResponse {
  result: {
    status: string,
    export_id: number
  }
}

export async function exportUnattachedProducts (params: ExportUnattachedProductsParams): Promise<ExportUnattachedProductsResponse> {
  return await req.post('unattached-products/create-export', { ...params })
}

export interface RelatedProductParams {
  type: string,
  productId: number,
  marketplaceProductId: number
}

export interface RelatedProductResponse {
  status: string,
  message: string,
  code: number
}

export async function relatedProduct (params: RelatedProductParams): Promise<RelatedProductResponse> {
  return await req.post('unattached-products/bind-product', { ...params })
}

export interface ImportUnattachedProductsResponse {
  success: boolean
  import_id : number | null
}

export async function importUnattachedProducts (file: File): Promise<ImportUnattachedProductsResponse> {
  const formData = new FormData()
  formData.append('file', file)
  return await req.upload('unattached-products/upload-excel', formData)
}
