import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select__label text-big",
  "data-testid": "select__label"
}
const _hoisted_2 = ["disabled"]
const _hoisted_3 = {
  key: 1,
  class: "select__list",
  "data-testid": "select__list"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.currentItem)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["select", _ctx.classObject]),
        ref: "select",
        "data-testid": "select"
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: _normalizeClass(["select__button text", {'select__button_active' : _ctx.isOpen }]),
          type: "button",
          disabled: _ctx.disabled,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args))),
          "data-testid": "select__button"
        }, _toDisplayString(_ctx.currentItem.name), 11, _hoisted_2),
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["select__item text", {'select__item_active': item.id === _ctx.currentItem.id }]),
                  key: i,
                  onClick: ($event: any) => (_ctx.changeSelect(item)),
                  "data-testid": "select__item"
                }, _toDisplayString(item.name), 11, _hoisted_4))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}