import req from '@/assets/scripts/request'

export interface GetExportParams {
  export_id: number | null;
}

export interface GetExportResponse {
  status: 'success' | 'error';
  link: string;
}

export async function getExport (params: GetExportParams): Promise<GetExportResponse> {
  return await req.get('export/get-export', { ...params })
}
