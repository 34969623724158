import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_head_meta = _resolveComponent("head-meta")!
  const _component_page_spinner = _resolveComponent("page-spinner")!
  const _component_info_board = _resolveComponent("info-board")!
  const _component_flex_column = _resolveComponent("flex-column")!
  const _component_toggle_switch = _resolveComponent("toggle-switch")!
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_flex_row = _resolveComponent("flex-row")!
  const _component_search_form = _resolveComponent("search-form")!
  const _component_date_range = _resolveComponent("date-range")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_export_button = _resolveComponent("export-button")!
  const _component_general_sales_table = _resolveComponent("general-sales-table")!
  const _component_app_page = _resolveComponent("app-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_head_meta, { "title-page": "Главная страница MPDesk" }),
    _createVNode(_component_app_page, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_page_spinner, { key: 0 }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_flex_row, {
                gap: 16,
                bottom: 32
              }, {
                default: _withCtx(() => [
                  (_ctx.todaySalesData && _ctx.allSalesData)
                    ? (_openBlock(), _createBlock(_component_flex_column, {
                        key: 0,
                        gap: 16
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_info_board, {
                            color: 'success',
                            "title-html": 'Заказали<br>за сегодня',
                            headline: _ctx.today,
                            values: _ctx.totalOrderValues
                          }, null, 8, ["headline", "values"]),
                          _createVNode(_component_info_board, {
                            color: 'info',
                            "title-html": 'Продано<br>за сегодня',
                            headline: _ctx.today,
                            values: _ctx.totalSalesValues
                          }, null, 8, ["headline", "values"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.chartView === 'sum')
                    ? (_openBlock(), _createBlock(_component_line_chart, {
                        key: 1,
                        type: 'expanded',
                        "chart-data": _ctx.chartData.sum,
                        "additional-chart-data": _ctx.chartData.count,
                        units: '₽',
                        "additional-units": 'шт.',
                        onSetFullscreen: _ctx.setFullscreen
                      }, {
                        default: _withCtx(() => [
                          (!this.isLineChartFullscreen)
                            ? (_openBlock(), _createBlock(_component_toggle_switch, {
                                key: 0,
                                values: _ctx.chartViews,
                                "start-value": _ctx.chartView,
                                onChangeValue: _ctx.changeChartView
                              }, null, 8, ["values", "start-value", "onChangeValue"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["chart-data", "additional-chart-data", "additional-units", "onSetFullscreen"]))
                    : (_ctx.chartView === 'count')
                      ? (_openBlock(), _createBlock(_component_line_chart, {
                          key: 2,
                          type: 'expanded',
                          "chart-data": _ctx.chartData.count,
                          "additional-chart-data": _ctx.chartData.sum,
                          units: 'шт.',
                          "additional-units": '₽',
                          onSetFullscreen: _ctx.setFullscreen
                        }, {
                          default: _withCtx(() => [
                            (!this.isLineChartFullscreen)
                              ? (_openBlock(), _createBlock(_component_toggle_switch, {
                                  key: 0,
                                  values: _ctx.chartViews,
                                  "start-value": _ctx.chartView,
                                  onChangeValue: _ctx.changeChartView
                                }, null, 8, ["values", "start-value", "onChangeValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["chart-data", "additional-chart-data", "units", "onSetFullscreen"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_flex_row, {
                gap: 12,
                bottom: 12,
                align: 'end'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_search_form, {
                    label: 'Название или артикул',
                    placeholder: 'Поиск...',
                    onSendForm: _ctx.search,
                    "start-value": _ctx.query
                  }, null, 8, ["placeholder", "onSendForm", "start-value"]),
                  (_ctx.startRange)
                    ? (_openBlock(), _createBlock(_component_date_range, {
                        key: 0,
                        onChangeDate: _ctx.changeDateRange,
                        "start-range": _ctx.startRange
                      }, null, 8, ["onChangeDate", "start-range"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_custom_select, {
                    label: 'Статус',
                    "current-item": _ctx.currentStatus,
                    list: _ctx.statusList,
                    onChangeSelect: _ctx.changeStatus
                  }, null, 8, ["current-item", "list", "onChangeSelect"]),
                  _createVNode(_component_custom_select, {
                    label: 'Маркеры',
                    "current-item": _ctx.currentMarker,
                    list: _ctx.markerList,
                    onChangeSelect: _ctx.changeMarker
                  }, null, 8, ["current-item", "list", "onChangeSelect"]),
                  _createVNode(_component_export_button, {
                    onExportToExcel: _ctx.exportToExcel,
                    "in-progress": _ctx.exportInProgress
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Выгрузить в Excel")
                    ]),
                    _: 1
                  }, 8, ["onExportToExcel", "in-progress"])
                ]),
                _: 1
              }),
              _createVNode(_component_general_sales_table, {
                "data-table": _ctx.salesData,
                "marketplace-id": 1,
                sort: _ctx.currentSort,
                onChangePage: _ctx.changePage,
                onChangePageSize: _ctx.changePageSize,
                onChangeSort: _ctx.sort
              }, null, 8, ["data-table", "sort", "onChangePage", "onChangePageSize", "onChangeSort"])
            ], 64))
      ]),
      _: 1
    })
  ], 64))
}