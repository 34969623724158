export function getToday () {
  const date = new Date()
  return getFormatDate(date)
}

export function getRelativeDate (dayDifference: number): Date {
  const today = new Date()
  const relativeDate = new Date()
  relativeDate.setDate(today.getDate() + dayDifference)
  return relativeDate
}

export function getTwoWeeksAgo () {
  const twoWeeksAgo = getRelativeDate(-14)
  return getFormatDate(twoWeeksAgo)
}

export function getTwoWeeksAhead () {
  const twoWeeksAgo = getRelativeDate(14)
  return getFormatDate(twoWeeksAgo)
}

export function getMonthAgo () {
  const monthAgo = getRelativeDate(-30)
  return getFormatDate(monthAgo)
}

export function getFormatDate (date: Date): string {
  const year = date.toLocaleDateString('ru', { year: 'numeric' })
  const month = date.toLocaleDateString('ru', { month: '2-digit' })
  const day = date.toLocaleDateString('ru', { day: '2-digit' })
  return `${year}-${month}-${day}`
}

export function getCurrentDateWithTime (): string {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  const today = new Date()
  return today.toLocaleString('ru', options)
}

export function formatDateFromTimestamp (timestamp: number): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' }
  return new Date(timestamp * 1000).toLocaleString('ru', options)
}

export function formatDateWithHoursFromTimestamp (timestamp: number): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
  return new Date(timestamp * 1000).toLocaleString('ru', options)
}
