import req from '@/assets/scripts/request'
import { NoteParams } from '@/types/note'

export interface MarkersItem {
  id: number,
  name: string,
  date: string,
  'products_count': number
}

export interface GetMarkersParams {
  sort: string
}

export interface GetMarkersResponse {
  status: 'success' | 'error',
  markers: MarkersItem[]
}

export async function getMarkers (params?: GetMarkersParams): Promise<GetMarkersResponse> {
  return await req.post('markers', { ...params })
}

export interface CreateMarkersParams {
  name: string
}

export interface CreateMarkersResponse {
  status: 'success' | 'error',
  notes: NoteParams[]
  markerId: number
}

export async function createMarker (params: CreateMarkersParams): Promise<CreateMarkersResponse> {
  return await req.post('markers/create', { ...params })
}

// Ответ
export interface RemoveMarkerResponse {
  status: 'success' | 'error'
}

export async function removeMarker (id: number): Promise<RemoveMarkerResponse> {
  return await req.post(`markers/${id}/remove`)
}

export interface BindProductsToMarkerParams {
  productsIds: number[],
}

export interface BindProductsToMarkerResponse {
  status: 'error' | 'success'
}

export async function bindProductsToMarker (id: number, params: BindProductsToMarkerParams): Promise<BindProductsToMarkerResponse> {
  return await req.post(`markers/${id}/bind-products`, params)
}

export interface ExcludeProductsParams {
  productsIds: number[]
}

export interface ExcludeProductsResponse {
  status: 'error' | 'success'
}

export async function excludeProductsFromMarker (id: number, params: ExcludeProductsParams): Promise<ExcludeProductsResponse> {
  return await req.post(`markers/${id}/exclude-products`, params)
}

export interface GetMarkerResponse {
  status: 'error' | 'success';
  name: string;
  products_count: number;
}

export async function getMarker (id: number): Promise<GetMarkerResponse> {
  return await req.post(`markers/${id}`)
}

export interface EditMarkerParams {
  name: string
}

export interface EditMarkerResponse {
  status: 'success' | 'error',
  notes: NoteParams[]
}

export async function editMarker (id: number, params: EditMarkerParams): Promise<EditMarkerResponse> {
  return await req.post(`markers/${id}/edit`, { ...params })
}

export interface UploadMarkerExcelFileResponse {
  success: boolean,
  import_id: number
}

export async function uploadMarkerExcelFile (id: number, file: File): Promise<UploadMarkerExcelFileResponse> {
  const formData = new FormData()
  formData.append('file', file)
  return await req.upload(`markers/${id}/upload-excel`, formData)
}

export interface GetUnattachedProductsToMarkerParams {
  notRelatedOzon?: boolean,
  notRelatedWb?: boolean,
  page: number,
  pageSize: number,
  searchStr?: string,
  sort: string,
  status?: number | null
}

export interface UnattachedProductToMarker {
  articul_wb: string;
  articul_ozon: string;
  id: number;
  name: string;
  date: {
    day: string;
    time: string;
  },
  image: string;
  size: string;
  price: number;
  count: {
    value: number;
    units: number;
  },
  status: {
    name: string;
    id: number
  },
  markers: {
    id: number;
    name: string
  }[]
}

export interface GetUnattachedProductsToMarkerResponse {
  products: UnattachedProductToMarker[];
  productCount: number;
  pageSize: number;
  pageCount: number;
  page: number;
}

export async function getUnattachedProductsToMarker (id: number, params: GetUnattachedProductsToMarkerParams): Promise<GetUnattachedProductsToMarkerResponse> {
  return await req.post(`markers/${id}/unattached-products`, params)
}
