import req from '@/assets/scripts/request'

export interface GetCostPriceParams {
  id?: number;
  to_transfer?: number;
  searchStr: string;
  cost_price?: number;
  page: number;
  pageSize: number;
  sort: string;
  payment_reason: string[]
}

export interface CostPriceItem {
  id: number;
  article: string;
  payment_reason: string;
  number: number;
  to_transfer_rub: number;
  cost_price_rub: number;
  wb_realization_product_id: string
}

export interface GetCostPriceResponse {
  items: CostPriceItem[];
  productCount: number;
  pageSize: number;
  pageCount: number;
  page: number;
}

export async function getWbCostPrice (params: GetCostPriceParams): Promise<GetCostPriceResponse> {
  return await req.post('wb/cost-price', params)
  // return new Promise((resolve, reject) => {
  //   resolve({
  //     items: [{
  //       id: 1,
  //       article: 'Артикул1',
  //       payment_reason: 'Продажа',
  //       number: 711311,
  //       to_transfer_rub: 100,
  //       cost_price_rub: 0,
  //       wb_realization_product_id: '11212'
  //     }, {
  //       id: 2,
  //       article: 'Артикул2',
  //       payment_reason: 'Логистика',
  //       number: 711312,
  //       to_transfer_rub: 200,
  //       cost_price_rub: 0,
  //       wb_realization_product_id: '11212'
  //     }],
  //     productCount: 1,
  //     pageSize: 1,
  //     pageCount: 1,
  //     page: 1
  //   })
  // })
}

export interface CreateCostPriceExportParams {
  id?: number;
  searchStr: string;
  payment_reason: string[];
}

export interface CreateCostPricExportSuccess {
  status: 'waiting',
  export_id: number
}

export interface CreateCostPriceExportError {
  status: 'error',
}

export interface CreateExportResponse {
  result: CreateCostPricExportSuccess | CreateCostPriceExportError
}

export async function createWbCostPriceExport (params: CreateCostPriceExportParams): Promise<CreateExportResponse> {
  return await req.post('wb/cost-price/create-export', params)
}

export interface UploadCostPriceFileResponse {
  success: boolean,
  import_id: number
}

export async function uploadWbCostPriceFile (file: File): Promise<UploadCostPriceFileResponse> {
  const formData = new FormData()
  formData.append('file', file)
  return await req.upload('wb/cost-price/upload-excel', formData)
}

export interface UpdateCostPriceParams {
  cost_price: number;
}

export interface UpdateCostPriceResponse {
  status: 'success' | 'error'
}

export async function updateWbCostPrice (id: number, params: UpdateCostPriceParams): Promise<UpdateCostPriceResponse> {
  return await req.post(`wb/cost-price/${id}/update`, params)
}

export interface IsThereWithoutCostPriceResponse {
  status:'success' | 'error';
  exists: boolean;
  count: number;
}

export async function isThereWithoutWbCostPrice (): Promise<IsThereWithoutCostPriceResponse> {
  return await req.post('wb/cost-price/is-there-without-cost-price')
}

export interface UpdateCostPricesItem {
  id: number;
  cost_price: number;
}

export interface UpdateCostPricesParams {
  cost_prices: UpdateCostPricesItem[]
}

export interface UpdateCostPricesError {
  status: 'error';
  message: string;
  code: number;
}

export async function updateWbCostPrices (params: UpdateCostPricesParams): Promise<UpdateCostPricesError | any> {
  return await req.post('wb/cost-price/update', params)
}
