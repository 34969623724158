import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "toggle-switch" }
const _hoisted_2 = { class: "toggle-switch__label" }
const _hoisted_3 = ["name", "value"]
const _hoisted_4 = { class: "toggle-switch__text text-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item) => {
      return (_openBlock(), _createElementBlock("label", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          class: "toggle-switch__input",
          type: "radio",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          name: _ctx.name,
          value: item.value,
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeValue && _ctx.changeValue(...args)))
        }, null, 40, _hoisted_3), [
          [_vModelRadio, _ctx.value]
        ]),
        _createElementVNode("span", _hoisted_4, _toDisplayString(item.text), 1)
      ]))
    }), 256))
  ]))
}