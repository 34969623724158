import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_button = _resolveComponent("auth-button")!
  const _component_sms_code_modal = _resolveComponent("sms-code-modal")!
  const _component_sms_code_success = _resolveComponent("sms-code-success")!
  const _component_sms_code_auth = _resolveComponent("sms-code-auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_auth_button, {
      onClickButton: _ctx.clickButton,
      "is-auth": _ctx.isAuth
    }, null, 8, ["onClickButton", "is-auth"]),
    (_ctx.isOpenSmsCodeModal)
      ? (_openBlock(), _createBlock(_component_sms_code_modal, {
          key: 0,
          onCloseModal: _ctx.closeSmsCodeModal,
          onSuccess: _ctx.showSuccess
        }, null, 8, ["onCloseModal", "onSuccess"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenSuccessModal)
      ? (_openBlock(), _createBlock(_component_sms_code_success, {
          key: 1,
          onCloseModal: _ctx.closeSuccessModal
        }, null, 8, ["onCloseModal"]))
      : _createCommentVNode("", true),
    (_ctx.isOpenAuthModal)
      ? (_openBlock(), _createBlock(_component_sms_code_auth, {
          key: 2,
          onCloseModal: _ctx.closeAuthModal
        }, null, 8, ["onCloseModal"]))
      : _createCommentVNode("", true)
  ], 64))
}