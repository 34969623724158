export default {
  getCookie (name: string) {
    // eslint-disable-next-line
        // @ts-ignore 
    const matches = document.cookie.match(new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
  },

  deleteCookie (name: string) {
    this.setCookie(name, '', {
      'max-age': -1
    })
  },

  setCookie (name: string, value: string, options = {}) {
    options = {
      path: '/',
      // при необходимости добавьте другие значения по умолчанию
      ...options
    }

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey
      // eslint-disable-next-line
            // @ts-ignore
      const optionValue = options[optionKey]
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue
      }
    }

    document.cookie = updatedCookie
  }
}
