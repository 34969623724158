import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getLastUpdate } from '@/assets/scripts/api/update'
import HomePage from '@/views/HomePage.vue'
import Storage from '@/assets/scripts/Storage'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { checkAuth: true }
  },
  {
    path: '/abc-analysis',
    name: 'ABCAnalysis',
    component: () => import('../views/ABCAnalysisPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/balances/:marketplace(wb|ozon)',
    name: 'Balances',
    component: () => import('../views/balances/BalancesPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'BalancesMain',
        component: () => import('../views/balances/BalancesMainPage.vue')
      },
      {
        path: 'dynamics',
        name: 'BalancesDynamics',
        component: () => import('../views/balances/BalancesDynamicsPage.vue')
      }
    ]
  },
  {
    path: '/balances/:marketplace(wb|ozon)/stocks/:id',
    name: 'StockBalances',
    component: () => import('../views/balances/StockBalancesPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'StockBalancesMain',
        component: () => import('../views/balances/StockBalancesMainPage.vue')
      },
      {
        path: 'dynamics',
        name: 'StockBalancesDynamics',
        component: () =>
          import('../views/balances/StockBalancesDynamicsPage.vue')
      }
    ]
  }, {
    path: '/cost-price/:marketplace(wb|ozon)',
    name: 'CostPrice',
    component: () => import('../views/CostPricePage.vue'),
    meta: { checkAuth: true }
  }, {
    path: '/discount',
    name: 'Discount',
    component: () => import('../views/DiscountPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/hypotheses/:marketplace(wb|ozon)?',
    name: 'Hypotheses',
    component: () => import('../views/HypothesesPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/hypotheses/:marketplace(wb|ozon)/:id',
    component: () => import('../views/hypothesis/HypothesisPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'HypothesisMain',
        component: () => import('../views/hypothesis/HypothesisMainPage.vue'),
        props: true
      },
      {
        path: 'products',
        name: 'HypothesisProducts',
        component: () => import('../views/hypothesis/HypothesisProductsPage.vue')
      }
    ]
  },
  {
    path: '/hypotheses/create',
    name: 'HypothesisCreate',
    component: () => import('../views/hypothesis/HypothesisCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: () => import('../views/IntegrationsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/integrations/:id',
    name: 'IntegrationMain',
    component: () => import('../views/integration/IntegrationMainPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/job-logs',
    name: 'JobLogs',
    component: () => import('../views/JobLogsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/logistics/:marketplace(wb|ozon)',
    name: 'Logistics',
    component: () => import('../views/LogisticsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/markers',
    name: 'Markers',
    component: () => import('../views/MarkersPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/markers/:id',
    component: () => import('../views/marker/MarkerPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'MarkerMain',
        component: () => import('../views/marker/MarkerMainPage.vue'),
        props: true
      },
      {
        path: 'related-products',
        name: 'MarkerRelatedProducts',
        component: () => import('../views/marker/MarkerRelatedProductsPage.vue')
      }, {
        path: 'unrelated-products',
        name: 'MarkerUnrelatedProducts',
        component: () => import('../views/marker/MarkerUnrelatedProductsPage.vue')
      }
    ]
  },
  {
    path: '/markers/create',
    name: 'MarkerCreate',
    component: () => import('../views/marker/MarkerCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/product-moving/:id',
    name: 'ProductMoving',
    component: () => import('../views/ProductMovingPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/ProductsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/products/:id',
    component: () => import('../views/product/ProductPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'ProductMain',
        component: () => import('../views/product/ProductMainPage.vue'),
        props: true
      },
      {
        path: 'balances',
        name: 'ProductBalances',
        component: () => import('../views/product/ProductBalancesPage.vue')
      },
      {
        path: 'orders',
        name: 'ProductOrders',
        component: () => import('../views/product/ProductOrdersPage.vue'),
        props: true
      },
      {
        path: 'pricing',
        name: 'ProductPricing',
        component: () => import('../views/product/ProductPricingPage.vue'),
        props: true
      },
      {
        path: 'realization',
        name: 'ProductRealization',
        component: () => import('../views/product/ProductRealizationPage.vue'),
        props: true
      },
      {
        path: 'supplies',
        name: 'ProductSupplies',
        component: () => import('../views/product/ProductSuppliesPage.vue')
      }
    ]
  },
  {
    path: '/products/create',
    name: 'ProductCreate',
    component: () => import('../views/product/ProductCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/profits/:marketplace(wb|ozon)?',
    name: 'Profits',
    component: () => import('../views/profits/ProfitsPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'ProfitsMain',
        component: () => import('../views/profits/ProfitsMainPage.vue'),
        props: true
      }, {
        path: 'products',
        name: 'ProfitsProducts',
        component: () => import('../views/profits/ProfitsProductsPage.vue'),
        props: true
      }
    ]
  },
  {
    path: '/profits/:marketplace(wb|ozon)/:id(\\d+)',
    component: () => import('../views/profit/ProfitPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'ProfitMain',
        component: () => import('../views/profit/ProfitMainPage.vue'),
        props: true
      }, {
        path: 'actions',
        name: 'ProfitActions',
        component: () => import('../views/profit/ProfitActionsPage.vue')
      }, {
        path: 'sales',
        name: 'ProfitSales',
        component: () => import('../views/profit/ProfitSalesPage.vue')
      }
    ]
  },
  {
    path: '/sales/:marketplace(wb|ozon)',
    name: 'Sales',
    component: () => import('../views/SalesPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stock-docs',
    name: 'StockDocs',
    component: () => import('../views/StockDocsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stock-docs/:id',
    component: () => import('../views/stock-doc/StockDocPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'StockDocMain',
        component: () => import('../views/stock-doc/StockDocMainPage.vue'),
        props: true
      },
      {
        path: 'history',
        name: 'StockDocHistory',
        component: () => import('../views/stock-doc/StockDocHistoryPage.vue')
      },
      {
        path: 'products',
        name: 'StockDocProducts',
        component: () => import('../views/stock-doc/StockDocProductsPage.vue')
      }
    ]
  },
  {
    path: '/stock-docs/create',
    name: 'StockDocCreate',
    component: () => import('../views/stock-doc/StockDocCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: () => import('../views/StocksPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stocks/:id',
    name: 'StockMain',
    component: () => import('../views/stock/StockMainPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stocks/:id/products',
    name: 'StockProducts',
    component: () => import('../views/stock/StockProductsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stocks/:id/products-moving',
    name: 'StockProductsMoving',
    component: () => import('../views/stock/StockProductsMovingPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/stocks/create',
    name: 'StockCreate',
    component: () => import('../views/stock/StockCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/supplies/:marketplace(wb|ozon)',
    name: 'Supplies',
    component: () => import('../views/SuppliesPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/supplies/:marketplace(wb|ozon)/:id',
    component: () => import('../views/supply/SupplyPage.vue'),
    meta: { checkAuth: true },
    children: [
      {
        path: '',
        name: 'SupplyMain',
        component: () => import('../views/supply/SupplyMainPage.vue'),
        props: true
      }, {
        path: 'products',
        name: 'SupplyProducts',
        component: () => import('../views/supply/SupplyProductsPage.vue')
      }
    ]
  },
  {
    path: '/supplies-fbs/:marketplace(wb|ozon)',
    name: 'SuppliesFbs',
    component: () => import('../views/SuppliesFbsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/transfers/:marketplace(wb|ozon)?',
    name: 'Transfers',
    component: () => import('../views/TransfersPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/transfers/:marketplace(wb|ozon)/:id',
    component: () => import('../views/transfer/TransferPage.vue'),
    children: [
      {
        path: '',
        name: 'TransferMain',
        component: () => import('../views/transfer/TransferMainPage.vue'),
        props: true
      },
      {
        path: 'products',
        name: 'TransferProducts',
        component: () => import('../views/transfer/TransferProductsPage.vue')
      }
    ],
    meta: { checkAuth: true }
  },
  {
    path: '/transfers/create',
    name: 'TransferCreate',
    component: () => import('../views/transfer/TransferCreatePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/unattached-barcodes',
    name: 'UnattachedBarcodes',
    component: () => import('../views/UnattachedBarcodesPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/unattached-products',
    name: 'UnattachedProducts',
    component: () => import('../views/UnattachedProductsPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/unattached-products/synchronize',
    name: 'UnattachedProductsSynchronize',
    component: () => import('../views/UnattachedProductsSynchronizePage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/warehouse-demand',
    name: 'WarehouseDemand',
    component: () => import('../views/WarehouseDemandPage.vue'),
    meta: { checkAuth: true }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: () => import('../views/ErrorPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters.accountsList
  const accountPick = store.getters.accountPick
  getLastUpdate().then()
  if (!to.meta || !to.meta.checkAuth) return next()
  // eslint-disable-next-line
  // @ts-ignore
  if (isAuthenticated === undefined) return next({ name: 'Login' })
  if (to.name === 'Login' && !Storage.user.isGuest) {
    return next({ name: 'Home' })
  }
  if ((to.name === 'JobLogs' || to.name === 'Integrations' || to.name === 'IntegrationCreate' || to.name === 'IntegrationMain') && accountPick.role !== 'Администратор') {
    return next({ name: 'Home' })
  }
  next()
})

export default router
