import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "search-form__label" }
const _hoisted_2 = {
  key: 0,
  class: "search-form__text text-big",
  "data-testid": "search-form__text"
}
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: "search-form",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
    "data-testid": "search-form"
  }, [
    _createElementVNode("label", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        class: "search-form__input input_border text",
        type: "search",
        placeholder: _ctx.placeholder,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
        "data-testid": "search-form__input"
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.query]
      ])
    ])
  ], 32))
}