import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "export-button" }
const _hoisted_2 = {
  key: 0,
  class: "export-button__progress text",
  "data-testid": "export-button__progress"
}
const _hoisted_3 = {
  class: "export-button__button-text text",
  "data-testid": "export-button__button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.inProgress)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Выгружается..."))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "export-button__button",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportToExcel && _ctx.exportToExcel(...args))),
          "data-testid": "export-button__button"
        }, [
          _createElementVNode("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
  ]))
}