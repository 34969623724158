import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "date-range" }
const _hoisted_2 = {
  key: 0,
  class: "date-range__text text-big"
}
const _hoisted_3 = { class: "date-range__list" }
const _hoisted_4 = { class: "date-range__item" }
const _hoisted_5 = { class: "date-range__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_input = _resolveComponent("date-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_date_input, {
          placeholder: '01.01.2022',
          onChangeDate: _ctx.changeStart,
          "start-value": _ctx.range.start,
          size: _ctx.size
        }, null, 8, ["onChangeDate", "start-value", "size"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_date_input, {
          placeholder: '01.01.2022',
          onChangeDate: _ctx.changeEnd,
          "start-value": _ctx.range.end,
          size: _ctx.size
        }, null, 8, ["onChangeDate", "start-value", "size"])
      ])
    ])
  ]))
}