import { Status } from '@/types/filters'
import { AbcAnalysisCategory } from '@/types/abc-analysis-category'

export const WB_MARKETPLACE_ID = 1
export const OZON_MARKETPLACE_ID = 0

export const ozonMarketplace = {
  name: 'Ozon',
  shortName: 'ozon',
  id: OZON_MARKETPLACE_ID
} as const

export const wbMarketplace = {
  name: 'Wildberries',
  shortName: 'wb',
  id: WB_MARKETPLACE_ID
} as const

export const marketplaces = [ozonMarketplace, wbMarketplace]

export const RECEIVING_DOC_TYPE_ID = 1
export const SHIPMENT_DOC_TYPE_ID = 2
export const UTILIZATION_DOC_TYPE_ID = 3
export const TRANSFER_DOC_TYPE_ID = 4

export const RECEIVING_DOC = {
  name: 'Приемка',
  id: RECEIVING_DOC_TYPE_ID
} as const

export const SHIPMENT_DOC = {
  name: 'Отправка',
  id: SHIPMENT_DOC_TYPE_ID
} as const

export const UTILIZATION_DOC = {
  name: 'Утилизация',
  id: UTILIZATION_DOC_TYPE_ID
} as const

export const TRANSFER_DOC = {
  name: 'Перемещение между складами',
  id: TRANSFER_DOC_TYPE_ID
} as const

export const stockDocTypes = [RECEIVING_DOC, SHIPMENT_DOC, UTILIZATION_DOC, TRANSFER_DOC]

export const marketplaceStatuses: Status [] = [
  { name: 'Активный', id: 0, type: 'success' },
  { name: 'В архиве', id: 1, type: 'neutral' }
]

export const statuses: Status [] = [
  { name: 'Активный', id: 0, type: 'success' },
  { name: 'В архиве', id: 1, type: 'neutral' },
  { name: 'На модерации', id: 2, type: 'warning' }
]

export const AbcAnalysisCategories: AbcAnalysisCategory[] = [
  { name: 'A', id: 0 },
  { name: 'B', id: 1 },
  { name: 'C', id: 2 }
]

export const JOB_LOG_STATUS_PROGRESS = 0
export const JOB_LOG_STATUS_ERROR = 1
export const JOB_LOG_STATUS_SUCCESS = 2

export const jobStatuses: Status [] = [
  { name: 'В процессе', id: JOB_LOG_STATUS_PROGRESS, type: 'info' },
  { name: 'Ошибка', id: JOB_LOG_STATUS_ERROR, type: 'danger' },
  { name: 'Успешно', id: JOB_LOG_STATUS_SUCCESS, type: 'success' }
]

export interface Period {
  name: string;
  id: 7 | 28 | 84
}

export const periods: Period[] = [
  { name: 'Неделя', id: 7 },
  { name: 'Месяц', id: 28 },
  { name: '3 месяца', id: 84 }
]

export const justificationsForPayment = [
  'Продажа',
  'Возврат',
  'Логистика',
  'Сторно продаж',
  'Авансовая плата за товар без движения',
  'Корректная продажа',
  'Корректный возврат',
  'Логистика сторно',
  'Оплата брака',
  'Оплата по итогам инвентаризации',
  'Оплата потерянного товара',
  'Сторно возвратов',
  'Штрафы',
  'Штрафы и доплаты'
]
