import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "not-found" }
const _hoisted_2 = { class: "not-found__note" }
const _hoisted_3 = {
  key: 0,
  class: "not-found__title title",
  "data-testid": "not-found__title"
}
const _hoisted_4 = {
  key: 1,
  class: "not-found__text text",
  "data-testid": "not-found__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}