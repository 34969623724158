import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info-board__title headline"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "info-board__headline text-small"
}
const _hoisted_4 = {
  key: 3,
  class: "info-board__values"
}
const _hoisted_5 = { class: "info-board__value" }
const _hoisted_6 = {
  key: 0,
  class: "info-board__additional-value text-small"
}
const _hoisted_7 = {
  key: 1,
  class: "info-board__main-value text-big"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["info-board", _ctx.classObject])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : (_ctx.titleHtml)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "info-board__title headline",
            innerHTML: _ctx.titleHtml
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
    (_ctx.headline)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.headline), 1))
      : _createCommentVNode("", true),
    (_ctx.values.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item) => {
            return (_openBlock(), _createElementBlock("li", _hoisted_5, [
              (item.additional)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(item.additional), 1))
                : _createCommentVNode("", true),
              (item.main)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.main), 1))
                : _createCommentVNode("", true)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}