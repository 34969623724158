import req from '@/assets/scripts/request'

export interface GetCodeResponse {
  status: boolean
}

export async function getCode (): Promise<GetCodeResponse> {
  return await req.post('wb/auth/get-code')
}

export interface EnterCodeParams {
  code: number
}

export interface EnterCodeResponse {
  success: boolean
}

export async function enterCode (params: EnterCodeParams): Promise<EnterCodeResponse> {
  return await req.post('wb/auth/enter-code', params)
}
