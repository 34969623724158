import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "text-cell text-cell_light text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.list?.length)
    ? (_openBlock(), _createElementBlock("td", {
        key: 0,
        class: _normalizeClass(["text-cell", _ctx.classObject])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
          return (_openBlock(), _createElementBlock("span", {
            class: "text-cell__text text",
            key: i
          }, _toDisplayString(item.name), 1))
        }), 128))
      ], 2))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.text)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.route)
                ? (_openBlock(), _createElementBlock("td", {
                    key: 0,
                    class: _normalizeClass(["text-cell", _ctx.classObject])
                  }, [
                    _createVNode(_component_router_link, {
                      class: _normalizeClass(["text-cell__link text", _ctx.classLink]),
                      to: _ctx.route
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.text), 1)
                      ]),
                      _: 1
                    }, 8, ["to", "class"])
                  ], 2))
                : (_openBlock(), _createElementBlock("td", {
                    key: 1,
                    class: _normalizeClass(["text-cell text", _ctx.classObject])
                  }, _toDisplayString(_ctx.text), 3))
            ], 64))
          : (_openBlock(), _createElementBlock("td", _hoisted_1, "-"))
      ], 64))
}