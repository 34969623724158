import Storage from '../Storage'
import req from '../request'
import store from '@/store'
import { User } from '@/types/user'

export async function login (email: string, password: string, organization: string) {
  const res = await req.post('cabinet/login', { email, password, organization })
  if (res.status !== 'error') {
    store.commit('setAccounts', res.user)
  }
  return res
}

export async function logout () {
  store.commit('logout')
  return req.post('cabinet/logout', {})
}

export interface GetUserResponse {
  status: 'success' | 'error',
  user: User
}

export async function getUser (): Promise<GetUserResponse> {
  const data = Storage.user
  if (data) return data
  const res = await req.post('cabinet/is-guest')
  // Storage.user = res.user
  return res
}

export async function refreshUser (): Promise<User> {
  const res = await req.post('cabinet/is-guest')
  // Storage.user = res.user
  return res
}
