import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-box" }
const _hoisted_2 = { class: "modal-box__inner" }
const _hoisted_3 = { class: "modal-box__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "modal-box__layer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "modal-box__close",
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }),
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}