import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "date-input__text"
}
const _hoisted_2 = { class: "date-input__label" }
const _hoisted_3 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-input", _ctx.classObject])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: "date-input__input input_border text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        type: "text",
        placeholder: _ctx.placeholder,
        ref: "input",
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.eventFocus && _ctx.eventFocus(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.eventBlur && _ctx.eventBlur(...args))),
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.eventChange && _ctx.eventChange(...args)))
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.value]
      ])
    ])
  ], 2))
}